.repeating-group {
  padding: 10px; /* Add padding for spacing */
  border: 1px solid #ddd; /* Optional: visual boundary */
  border-radius: 15px; /* Optional: rounded corners */
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Space between text and icons */
  align-items: left; /* Center items vertically */
  overflow: hidden; /* Prevent overflow */
  max-height: 50px; 
  margin-bottom: 10px;/* Set max height for consistent sizing */
}

.repeating-group span {
   /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Show ellipsis for overflow */
  /* Allow the text to take up available space */
}

.icon-container {
  display: flex; /* Flexbox for icons */
  align-items: center; /* Center icons vertically */
}
