.privacy-policy-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
  }
  
  .privacytopdiv {    
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  
  .back-button { 
    color: var(--main-color);
    font-size: 16px;
    font-size: 1.8em;
    gap: 15px;
    top: 10px;
    left: 10px;
    border: none;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center; 
  }
  
  #policy-content {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 1em;
    line-height: 1.2;
    margin-top: 20px;
    font-family: Noto Sans;
    font-weight: 500;
    padding-bottom: 20px;
    
  }
  
  .button-container {
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: flex-end;
    
  }
  
  .action-button {
    background-color:  var(--main-color);
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }


  @media(max-width:600px){


    .back-button {
      font-size: 1.2em;
      gap: 5px;
    }

    .privacy-policy-container {
      padding: 10px;
      margin-bottom: 60px;
    }

    .button-container {
      height: 5vw;
      width: 20%;
      gap: 10px;
      justify-content: center;
    }
  }
  

  